<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="예약자 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="linkClick"
        gridHeight="600px"
      >
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checkup-all-reserve-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        reserveDate: '',
        plantCd: '',
        heaCheckupPlanId: '',
        hospitalIds: '',
      }),
    },
  },
  data() {
    return {
      comboItems:[],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'reserveUserName',
            field: 'reserveUserName',
            label: '예약자',
            style: 'width:100px',
            align: 'center',
            type: 'link',
            sortable: false,
          },
          {
            name: 'hospitalName',
            field: 'hospitalName',
            label: '병원',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'hospitalLocation',
            field: 'hospitalLocation',
            label: '병원위치',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        reserveId: '',
        hospitalId: null,
        reserveName: '',
        reserveTime: '',
        reserveUserId: '',
        reserveUserName: '',
        reserveDate: '',
        reserveDay: '',
        reserveMonth: '',
        reserveYear: '',
      },
      editable: true,
      disable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.reserve.list.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.reserveDate) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          reserveDate: this.popupParam.reserveDate,
          plantCd: this.popupParam.plantCd,
          heaCheckupPlanId: this.popupParam.heaCheckupPlanId,
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data

        },);
      }
    },
    linkClick(row) {
      this.popupOptions.title = '종합검진 예약상세'; // 
      this.popupOptions.param = {
        reserveId: row ? row.reserveId : '',
        plantCd: this.popupParam.plantCd ? this.popupParam.plantCd : '',
        heaCheckupPlanId: this.popupParam.heaCheckupPlanId ? this.popupParam.heaCheckupPlanId : '',
        hospitalIds: this.popupParam.hospitalIds
      };
      this.popupOptions.target = () => import(`${'./checkUpReserveDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
  },
};
</script>
